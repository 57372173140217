import React from 'react'
import AuthLayoutContainer from '../../containers/AuthLayout/AuthLayout.container'
import MainPageArtistsContainer from '../../containers/MainPage/MainPageArtists/MainPageArtists.container'

import './MainPage.style.sass'

interface PropTypes {
  isHidden?: boolean; 
}

const block = 'main-page'

export default class MainPageComponent extends React.Component<PropTypes> {
  render(): JSX.Element {
    return (
      <div className={block}>
        <div className={`${block}__container`}>
          {this.props.isHidden ? (
            <>
            <div className="page-title page-title--align-center">
              Хотите стать участником фестиваля<br/>
              Ural Music Night?
            </div>
            <div 
              className={`${block}__subtitle`}
            >
              Прием заявок от музыкантов до 18 марта 2025
            </div>
            </>
          ) : (
            <>
              <div className="page-title page-title--align-center">
                ПРИЕМ ЗАЯВОК НА УЧАСТИЕ В ФЕСТИВАЛЕ <br/>
                URAL MUSIC NIGHT ЗАВЕРШЕН!
              </div>
            </>
          )}

          <AuthLayoutContainer />
        </div>

        {/* <MainPageArtistsContainer /> */}
      </div>
    )
  }
}